import Radium from 'radium';
import Footer from "../components/Footer";

function About() {
    const end = (new Date).getFullYear() - 2017;
    return (
        <div>
            {/*Header Banner */}
            <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4" data-background="/img/about/image.png" style={{backgroundPosition: "center"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center caption mt-90">
                            <h1>À Propos de nous</h1>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="line-vr-section"/>
            {/*About*/}
            <div className="about section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-title">{end} Ans d'Expérience</div>
                            <h5 className="text-2xl font-bold text-gray-800 mb-4">
                                🚀 On ne code pas juste des applis. On crée des solutions qui ont du sens.
                            </h5>

                            <p className="text-base leading-relaxed mb-3">
                                <span className="font-semibold">IT INNOVE</span>, c’est une équipe de passionnés — devs, designers — qui transforme des idées en applis web et mobiles utiles, belles et performantes.
                            </p>

                            <p className="text-base leading-relaxed mb-3">
                                Notre mission : mettre l’innovation digitale au service de projets à impact, dans l’éducation, la société et le digital responsable.
                            </p>

                            <h6 className="text-xl font-bold text-gray-800 mt-8 mb-4">
                                🎯 Agence agile, humaine et créative
                            </h6>

                            <p className="text-base leading-relaxed mb-4">
                                On avance avec nos clients pour concevoir des solutions sur-mesure, alliant design et performance.
                            </p>

                            <h6 className="text-xl font-bold text-gray-800 mt-8 mb-4">
                                💡 Nos valeurs :
                            </h6>
                            <p className="text-base leading-relaxed mb-4">
                                <li><span className="font-semibold">Co-création</span> : ensemble, du début à la fin.</li>
                                <li><span className="font-semibold">Impact</span> : chaque projet a un vrai but.</li>
                                <li><span className="font-semibold">Éthique</span> : transparence et responsabilité.</li>
                                <li><span className="font-semibold">Créativité</span> : sortir des sentiers battus.</li>
                                <li><span className="font-semibold">Bienveillance</span> : pour nos clients et partenaires.</li>
                            </p>
                            {/*<p>La société IT INNOVE a été fondée en 2017 par M. Blaise BARRÉ. Elle a été bâtie sur un modèle convivial et familial.</p>*/}
                            {/*<p>Nous concevons </p>*/}
                            {/*<p>Architecture innovation tristique usto duision vitae diam neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus.</p>*/}

                        </div>
                        <div className="col-md-6">
                            <div className="about-img">
                                {/*<div className="img"> <img src="img/about.png" className="img-fluid" alt=""/> </div>*/}
                                <video controls width="100%" poster="/img/about/video/thumbnail/thumb.jpg">
                                    <source src="https://itinnove.s3.eu-west-3.amazonaws.com/video.mp4" type="video/mp4"/>

                                    Voir la
                                    <a href="https://player.vimeo.com/video/495474969?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479">video</a>.

                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Testimonial*/}
            <div className="team section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-15">
                            <div className="section-title">Team</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="item toright mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/Blaise.png" alt=""
                                                                     className="img-circle-about"/></div>
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Blaise Barré</h6> / <span>Gérant & développeur full-stack web et mobile</span>
                                        <p>Créateur de solutions logicielles complètes.</p>
                                        {/*<div className="social">*/}
                                        {/*    <div className="full-width">*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/cleo.png" alt="" className="img-circle-about"/></div>
                                {/*<div className="img"><img src="/vendor/img/itinnove/about/team/cleo.JPG" alt=""/></div>*/}
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Cléo Mattei</h6> /<br/> <span>Développeuse full-stack web et mobile</span>
                                        <p>Spécialite de l'intégration de solutions</p>
                                        {/*<div className="social">*/}
                                        {/*    <div className="full-width">*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item toright mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/Antonin.png" alt="" className="img-circle-about"/></div>
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Antonin Lebeau</h6> / <span>Développeur full-stack web et mobile</span>
                                        <p>Expert en développement d'applications sur-mesure</p>
                                        {/*<div className="social">*/}
                                        {/*    <div className="full-width">*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="item mb-30">
                                <div className="img-team-about"><img src="/img/about/portraits/Melvin.png" alt="" className="img-circle-about"/></div>
                                <div className="info valign">
                                    <div className="full-width">
                                        <h6>Melvin Poutrel</h6> / <br/><span>Développeur full-stack web et mobile</span>
                                        <p>Architecte de systèmes performants</p>
                                        {/*<div className="social">*/}
                                        {/*    <div className="full-width">*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-facebook"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-twitter"></i> </a>*/}
                                        {/*        <a href="#0" className="icon"> <i className="ti-linkedin"></i> </a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Clients*/}

            <div className="blog section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">Nos Clients</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/billa.jpg"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/hidora.jpg" alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/apiterra.jpg"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/intercaves.jpg"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/magiline.jpg"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client"
                                 src="/vendor/img/itinnove/clients/m__.jpg" alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/seineyonne.jpg"
                                 alt=""/>
                        </div>
                        <div className="frame-img-client">
                            <img className="img-client" src="/vendor/img/itinnove/clients/taittinger.jpg"
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default Radium(About);
