import Radium from 'radium';

function Navbar() {
    return (
        <div>


            <nav className="navbar navbar-expand-lg">
                <div className="banner-contact" data-overlay-dark="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <div className="row">
                                    <li className="ml-2 mr-3"><i className="ti-email"></i> <a
                                        href="mailto:blaise.barre@itinnove.com">itinnove.com</a></li>
                                    <li className="mr-3"><i className="ti-mobile"></i> <a
                                        href="tel:+33(0)6 63 28 61 88">+33
                                        (0)6 63 28 61 88</a></li>
                                    <li className="mr-2"><i className="ti-comment"></i> <a
                                        href="https://api.whatsapp.com/send?phone=33663286188"
                                        target={"_blank"}>Discutons</a></li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">

                    <div className="logo-wrapper mt-3">
                        <a className="logo" href="/"><img src="/vendor/img/logo-itinnove-white.png"/> </a>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span
                        className="icon-bar"><i className="ti-menu"></i></span></button>

                    <div className="collapse navbar-collapse mt-4" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item"><a
                                className="nav-link"
                                href="/">Accueil</a></li>

                            <li className="nav-item"><a
                                className="nav-link"
                                href="/about">À Propos</a></li>

                            <li className="nav-item"><a
                                className="nav-link"
                                href="/services">Nos Services</a></li>

                            {/*<li className="nav-item"><a
                                className="nav-link"
                                href="/productivity">Boostez votre Productivité</a></li>*/}

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" href="/devis">*/}
                            {/*        <div className="devis">Devis gratuit</div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}

                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" href="/meeting">*/}
                            {/*        <div className="rdv">Prendre un RDV</div>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Radium(Navbar);
